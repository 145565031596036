import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'item'];

  apply() {
    const value = this.inputTarget.value.toLowerCase();

    if (value === '') {
      for (let i = 0; i < this.itemTargets.length; i++) {
        this.itemTargets[i].classList.remove('hidden');
      }
      return;
    }

    for (let i = 0; i < this.itemTargets.length; i++) {
      const target = this.itemTargets[i];
      target.classList.toggle(
        'hidden',
        target.textContent.toLowerCase().indexOf(value) === -1
      );
    }
  }
}
