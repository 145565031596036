/* global $ */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable vars-on-top */
/* eslint-disable comma-dangle */
/* eslint-disable getter-return */
/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

const debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [
    'stockErrors',
    'stockContent',
    'boltsAndSmallCutsContainer',
    'showBoltsLink',
    'hideBoltsLink'
  ];

  connect() {
    this.getStock();

    if(this.hasStockErrorsTarget) {
      this.stockErrorsTarget.classList.add('hidden');
    }
  }

  get rootElement() {
    return document.getElementById('product-details-container');
  }

  get productStockElement() {
    return this.rootElement.querySelector('.stock .contents');
  }

  get productId() {
    // For the rug customizer, we need to check for this input element to get the "selected" product sku
    var stock_product_sku_input = this.rootElement.querySelector('input[name=stock_product_sku]');
    var found_product_sku = null;

    if(stock_product_sku_input && stock_product_sku_input.length) {
      found_product_sku = stock_product_sku_input.val();
    } else {
      found_product_sku = this.rootElement.dataset.productId;
    }

    return found_product_sku;
  }

  showBoltList() {
    if(this.hasShowBoltsLinkTarget) {
      this.showBoltsLinkTarget.classList.add('hidden');
    }
    if(this.hasHideBoltsLinkTarget) {
      this.hideBoltsLinkTarget.classList.remove('hidden');
    }
    if(this.hasBoltsAndSmallCutsContainerTarget) {
      this.boltsAndSmallCutsContainerTarget.classList.remove('hidden');
    }
  }

  hideBoltList() {
    if(this.hasShowBoltsLinkTarget) {
      this.showBoltsLinkTarget.classList.remove('hidden');
    }
    if(this.hasHideBoltsLinkTarget) {
      this.hideBoltsLinkTarget.classList.add('hidden');
    }
    if(this.hasBoltsAndSmallCutsContainerTarget) {
      this.boltsAndSmallCutsContainerTarget.classList.add('hidden');
    }
  }

  getStock() {
    if(!this.productId) {
      this.showStockError("missing product");
      return false;
    }

    $.ajax({
      method: 'GET',
      url: '/api/stock-and-pricing/' + this.productId,
      cache: false,
      timeout: 5000,
      error: (xhr, status, error) => {
        this.showStockError(error);
      }
    });
  }

  showStockError(error) {
    if(this.hasStockContentTarget) {
      this.stockContentTarget.classList.add('hidden');
    }
    if(this.hasStockErrorsTarget) {
      this.stockErrorsTarget.classList.remove('hidden');
    }
  }
}
