import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'sofaContainer',
    'sofaButton',
    'sleeperContainer',
    'sleeperButton',
  ]

  connect() {
    document.addEventListener('DOMContentLoaded', () => {
      this.showSofa();
    });
  }

  showSofa() {
    this.sofaContainerTarget.classList.remove('tw-hidden');
    this.sleeperContainerTarget.classList.add('tw-hidden');
    this.sofaButtonTarget.classList.add('active');
    this.sofaButtonTarget.disabled = true;
    this.sleeperButtonTarget.classList.remove('active');
    this.sleeperButtonTarget.disabled = false;
  }

  showSleeper() {
    this.sofaContainerTarget.classList.add('tw-hidden');
    this.sleeperContainerTarget.classList.remove('tw-hidden');
    this.sofaButtonTarget.classList.remove('active');
    this.sofaButtonTarget.disabled = false;
    this.sleeperButtonTarget.classList.add('active');
    this.sleeperButtonTarget.disabled = true;
  }
}
