import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'gridTitle',
    'brandGrid',
    'categoryGrid',
    'itemGrid',
    'baseBreadcrumb',
    'secondaryBreadcrumb',
    'categoryButton'
  ];

  initialize() {
    this.active_brand = null;
    this.active_category = null;
  }

  slugify(str) {
    return str.replace(/\s\(.+\)/g, '').split(' ').join('-').toLowerCase();
  };

  setHardwareTitle(title) {
    this.gridTitleTarget.innerHTML = title || 'Hardware Brands';
  }

  showBrands() {
    this.setHardwareTitle();
    this.brandGridTarget.classList.remove('hidden');
    this.categoryGridTarget.classList.add('hidden');
    this.itemGridTarget.classList.add('hidden');
    this.baseBreadcrumbTarget.classList.add('hidden');
    this.secondaryBreadcrumbTarget.classList.add('hidden');
  }

  showBrandCategories() {
    this.setHardwareTitle(this.active_brand);
    this.categoryGridTarget.classList.remove('hidden');
    this.itemGridTarget.classList.add('hidden');
    this.baseBreadcrumbTarget.classList.remove('hidden');
    this.secondaryBreadcrumbTarget.classList.add('hidden');
  }

  showCategory(e) {
    let brand_title = e.currentTarget.dataset.brand;
    this.active_brand = brand_title;
    this.setHardwareTitle(brand_title);
    this.brandGridTarget.classList.add('hidden');
    this.baseBreadcrumbTarget.classList.remove('hidden');
    Array.from(this.categoryGridTarget.children).forEach((element) => {
      if (element.dataset.brands.includes(this.active_brand.toLowerCase())) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    });
    this.categoryGridTarget.classList.remove('hidden');
  }

  showDownloads(e) {
    let category_title = e.currentTarget.querySelector('.category-label').textContent;
    this.active_category = e.currentTarget.dataset.category;
    this.setHardwareTitle(category_title);
    this.categoryGridTarget.classList.add('hidden');
    this.itemGridTarget.classList.remove('hidden');
    this.baseBreadcrumbTarget.classList.add('hidden');
    this.secondaryBreadcrumbTarget.classList.remove('hidden');
    this.categoryButtonTarget.innerHTML = this.active_brand;
    Array.from(this.itemGridTarget.children).forEach((element) => {
      let brand = this.slugify(element.dataset.brand);
      if (brand.includes(this.slugify(this.active_brand)) && element.dataset.category.includes(this.active_category)) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    });
  }
}
