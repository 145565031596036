import { Controller } from "stimulus";

export default class extends Controller {
  showMoreDetails(e) {
    $.ajax(e.target.getAttribute('data-url'), {
      method: "GET",
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
        contentType: 'text/javascript'
      },
      dataType: "script"
    }).fail((e) => {
      toastr.error(e.responseText);
    })
  }
}
