import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fauxForm', 'q2', 'order'];

  submit() {
    $(this.element).trigger('submit.rails');
  }

  get rootElement() {
    return document.getElementById('searchBuilder');
  }

  get ujsHelper() {
    return this.application.getControllerForElementAndIdentifier(
      this.rootElement,
      'ujs-helper'
    );
  }

  showSpinner() {
    this.ujsHelper.showSpinner();
  }

  hideSpinner() {
    this.ujsHelper.hideSpinner();
  }

  detectSubmission(event) {
    if (event.key === "Enter") {
      this.secondarySearch();      
    }
  }

  secondarySearch(){
    this.showSpinner();
    let data = {
      q2: this.q2Target.value,
      order: this.orderTarget.value
    };

    $.ajax(this.fauxFormTarget.getAttribute('data-url'), {
      method: "GET",
      headers: { 
        "X-CSRF-Token": $("meta[name=csrf-token]").prop("content"),
        contentType: 'text/javascript' 
      },
      dataType: "script",
      data: data
    })
    .done(() => {
      this.hideSpinner();
    })
  }
}
