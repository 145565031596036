import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'addForm'];
  
  search(e) {
    e.preventDefault();

    $.ajax({
      type: 'POST',
      url: this.formTarget.action,
      data: $(this.formTarget).serialize(),
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
      },
      dataType: 'script'
    }).error((e) => {
      toastr.error('You must provide a search term.');
    });
  }

  add_item(e) {
    e.preventDefault();
    
    let form = $(e.target).closest('form');
    
    toastr.info('Added item');

    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
      },
      dataType: 'script'
    }).error((e) => {
      toastr.error('There was an issue adding your item.');
    });
  }
}
