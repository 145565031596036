import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'toggle',
    'content',
    'extraContent',
    'alternateExtraContent',
  ];

  get selectedIndex() {
    for (let i = 0; i < this.contentTargets.length; i++) {
      if (this.contentTargets[i].classList.contains('selected')) {
        return i;
      }
    }
    return -1;
  }

  selectIndex(i) {
    if (i > -1 && i === this.selectedIndex) {
      i = -1;
    }
    for (let j = 0; j < this.contentTargets.length; j++) {
      if (j === i) {
        this.toggleTargets[j].classList.add('selected');
        this.contentTargets[j].classList.add('selected');
        this.reinitializeCarousels(this.contentTargets[j]);
      } else {
        this.toggleTargets[j].classList.remove('selected');
        this.contentTargets[j].classList.remove('selected');
      }
    }
    this.eachExtraContent((el, j, alternate) => {
      const hidden = !((j === i) ^ alternate);
      el.classList.toggle('hidden', hidden);
      if (!hidden) {
        this.reinitializeCarousels(el);
      }
    });
  }

  select(event) {
    event.preventDefault();

    this.toggleTargets.forEach((el, i) => {
      if (el.contains(event.target)) {
        this.selectIndex(i);
      }
    });
  }

  eachExtraContent(fn) {
    this.extraContentTargets.forEach((el) => {
      fn(el, parseInt(el.dataset.accordionIndex, 10), false);
    });
    this.alternateExtraContentTargets.forEach((el) => {
      fn(el, parseInt(el.dataset.accordionIndex, 10), true);
    });
  }

  reinitializeCarousels(el) {
    const els = el.querySelectorAll('[data-controller*="carousel"]');
    for (let i = 0; i < els.length; i++) {
      const controller = this.application.getControllerForElementAndIdentifier(
        els[i],
        'carousel'
      );
      if (controller == null) {
        continue;
      }
      controller.reinitialize();
    }
  }
}
