import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const expandableContent = document.querySelectorAll('.furniture-customizer .expandable-content');

    const ro = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const hasOverflow = entry.target.scrollHeight - 16 > entry.target.clientHeight;
        const btnExpand = entry.target.querySelector('button');
        btnExpand.style.display = (hasOverflow) ? 'block' : 'none';

        if (entry.target.classList.contains('expanded')) {
          btnExpand.style.display = 'block';
        }
      });
    });

    expandableContent.forEach(content => ro.observe(content));
  }

  expand_toggle(e) {
    e.currentTarget.parentNode.classList.toggle('expanded');
    const label = e.currentTarget.querySelector('span');
    label.textContent = (label.textContent === 'More') ? 'Less' : 'More';
  }
}

