import { Controller } from 'stimulus';

export default class extends Controller {
  close() {
    this.element.classList.remove('open');
  }

  closeOnEscape(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
}
