import { Controller } from "stimulus";

export default class extends Controller {
  copyLink(e) {
    const originalText  = e.target.innerText;
    let link = e.target.getAttribute("data-value");
    e.target.innerText = "Copied!";

    try {
      navigator.clipboard.writeText(link);
    } catch (err) {
      return console.error(err);
    }

    setTimeout(() => {
      e.target.innerText = originalText;
    }, 2000);
  }
}
