import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tab', 'content'];

  connect() {
    this.selectIndex(0);
  }

  selectIndex(i) {
    this.tabTargets.forEach((el, j) => {
      if (j === i) {
        el.classList.add('active');
        this.contentTargets[j].classList.add('active');
      } else {
        el.classList.remove('active');
        this.contentTargets[j].classList.remove('active');
      }
    });
  }

  select(event) {
    event.preventDefault();

    for (let i = 0; i < this.tabTargets.length; i++) {
      if (this.tabTargets[i].contains(event.target)) {
        this.selectIndex(i);
        return;
      }
    }
  }
}
