import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["actions", "count", "select"];

  get selectedQuotes() {
    return this.selectTargets.filter((el) => el.checked);
  }

  select(e) {
    const count = this.selectedQuotes.length;

    this.actionsTarget.classList.toggle("hidden", count === 0);

    const subject = `quote${count === 1 ? "" : "s"}`;
    this.countTarget.textContent = `${count} ${subject} selected`;
  }

  deleteSelected() {
    if (!confirm("Are you sure you want to delete the selected quotes?")) {
      return;
    }

    const ids = this.selectedQuotes.map(
      (el) => el.closest(".quote").dataset.quoteId
    );
    $.ajax("/my-account/quotes/destroy_many", {
      method: "POST",
      data: { ids: ids.join(",") },
    });

    this.actionsTarget.classList.toggle("hidden", true);
    this.selectedQuotes.forEach((el) => {
      el.closest(".quote-wrapper").remove();
    });
  }
}
