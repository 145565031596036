import { Controller } from "stimulus";
import qs from "qs";

const debounce = require("lodash/debounce");

export default class extends Controller {
  static targets = [
    "form",
    "orderTypeField",
    "quantityField",
    "quantityDecrementButton",
    "quantityIncrementButton",
    "leatherSquareFootageQuantityField",
    "leatherCuttingForApprovalField",
    "hiddenBackingField",
    "hiddenFinishField",
    "destinationSelection",
    "destinationName",
    "submit"
  ];

  initialize() {
    this.refresh_modal = debounce(this.refresh_modal, 100).bind(this);
  }

  connect() {
    this.toggle_destination_selection();
  }

  close_modal(e) {
    document.body.removeChild(document.getElementById("order-entry-modal-overlay"));
  }

  refresh_modal() {
    this.clear_special_processing();
    this.submitTarget.setAttribute("disabled", "disabled");

    $(this.formTarget).submit();
  }

  clear_special_processing() {
    if (this.orderTypeFieldTarget.value != "regular_order") {
      if (this.hasHiddenFinishFieldTarget) {
        this.hiddenFinishFieldTarget.value = "";
      }
      if (this.hasHiddenBackingFieldTarget) {
        this.hiddenBackingFieldTarget.value = "";
      }
    }
  }

  focus_quantity() {
    this.quantityFieldTarget.focus();
  }

  toggle_destination_selection(e) {
    if (this.hasDestinationNameTarget) {
      if (this.destinationSelectionTarget.value == "new") {
        this.destinationNameTarget.classList.toggle("hidden");
      } else {
        this.destinationNameTarget.classList.add("hidden");
      }
    }
  }

  open_special_processing_modal(e) {
    if (this.orderTypeFieldTarget.value == "") {
      toastr.error("Select an Order Type");
    } else if (this.orderTypeFieldTarget.value != "regular_order") {
      toastr.error("Special processing is only available for regular orders.");
    } else {
      let modal_already_open_modal = e.currentTarget.querySelector(".special_process_type_modal:not(.hidden)");
      if (modal_already_open_modal == null) {
        e.stopPropagation();
      }

      // see if another special processing modal is open
      let other_open_modals = this.formTarget.querySelectorAll(".special_process_type_modal:not(.hidden)");
      if (other_open_modals != null) {
        other_open_modals.forEach((element) => {
          element.classList.add("hidden");
        });
      }

      e.currentTarget.querySelector(".special_process_type_modal").classList.remove("hidden");
    }
  }

  maybe_close_special_processing_modal(e) {
    let open_modal = e.currentTarget.querySelector(".special_process_type_modal:not(.hidden)");
    if (open_modal != null) {
      open_modal.classList.add("hidden");
    }
  }

  select_special_processing_option(e) {
    let target_field = e.currentTarget.getAttribute("data-group") == "finishes" ? this.hiddenFinishFieldTarget : this.hiddenBackingFieldTarget;

    target_field.value = e.currentTarget.getAttribute("data-option");
    this.refresh_modal();
  }

  remove_special_processing_for_group(e) {
    let target_field = e.currentTarget.getAttribute("data-group") == "finishes" ? this.hiddenFinishFieldTarget : this.hiddenBackingFieldTarget;
    target_field.value = "";
    this.refresh_modal();
  }

  quantity_increment(e) {
    if (this.orderTypeFieldTarget.value == "") {
      toastr.error("Select an Order Type");
    } else {
      this.quantityFieldTarget.stepUp();
      if (this.quantityFieldTarget.value == this.quantityFieldTarget.getAttribute("max")) {
        this.quantityIncrementButtonTarget.disabled = true;
        this.quantityIncrementButtonTarget.classList.add("disabled");
        this.refresh_modal();
      }
      if (parseFloat(this.quantityFieldTarget.value) > parseFloat(this.quantityFieldTarget.getAttribute("min"))) {
        this.quantityDecrementButtonTarget.disabled = false;
        this.quantityDecrementButtonTarget.classList.remove("disabled");
      }
    }
  }

  quantity_decrement(e) {
    this.quantityFieldTarget.stepDown();
    if (this.quantityFieldTarget.value == this.quantityFieldTarget.getAttribute("min")) {
      this.quantityDecrementButtonTarget.disabled = true;
      this.quantityDecrementButtonTarget.classList.add("disabled");
    }
    this.refresh_modal();
  }

  quantity_typed(e) {
    // If someone typed a quantity that wasn't within the proper increment, set it to the minimum quantity
    let quantity_step = parseFloat(this.quantityFieldTarget.getAttribute("step"));
    if (quantity_step > 1 && parseFloat(this.quantityFieldTarget.value) % quantity_step != 0) {
      this.quantityFieldTarget.value = quantity_min;
    }
    this.refresh_modal();
  }

  hideUJSSearchSpinner() {
    let controller = this.application.getControllerForElementAndIdentifier(document.getElementById("searchBuilder"), "ujs-helper");
    if (controller) {
      controller.hideSpinner();
    }
  }

  // TODO: do this server side with the actual OrderEntry class
  get_order_submission_data() {
    let sellable_unit_field = this.formTarget.querySelector("#order_entry_sellable_unit");
    let sellable_unit = "unit";

    if (sellable_unit_field != null) {
      sellable_unit = sellable_unit_field.value;
    }

    let quantity = 1;
    if (this.hasQuantityFieldTarget) {
      quantity = this.quantityFieldTarget.value;
    }

    let data = {
      sku: this.formTarget.querySelector("#order_entry_product_id").value,
      product_type: this.formTarget.getAttribute("data-product-type"),
      vendor_number: this.formTarget.getAttribute("data-product-vendor-number"),
      order_type: this.orderTypeFieldTarget.value,
      sellable_unit: sellable_unit,
      quantity: quantity,
      sidemark: this.formTarget.querySelector("#order_entry_sidemark").value,
      leather_sqft_quantity: null,
      leather_cut_approval: null,
      piece_stat_quantity: null,
      piece_stat_directive: null,
      cart_item_id: null,
      cart_id: null,
      cart_name: null,
      quote_item_id: null,
      quote_id: null,
      quote_name: null,
      account_id: null,
      extended_product_data: {},
      customizations: {},
      cuts: [{ quantity: null, length: null }, { quantity: null, length: null }, { quantity: null, length: null }, { quantity: null, length: null }],
      special_processing: {},
    };

    if (this.formTarget.querySelector("#order_entry_leather_sqft_quantity")) {
      data["leather_sqft_quantity"] = this.formTarget.querySelector("#order_entry_leather_sqft_quantity").value;
    }

    if (
      this.formTarget.querySelector("#order_entry_leather_cut_approval") &&
      this.formTarget.querySelector("#order_entry_leather_cut_approval").checked
    ) {
      data["leather_cut_approval"] = "on";
    }

    if (sellable_unit == "piece" || sellable_unit == "halfpiece") {
      data["piece_stat_quantity"] = this.formTarget.querySelector("#order_entry_piece_stat_quantity").value;
      data["piece_stat_directive"] = this.formTarget.querySelector("#order_entry_piece_stat_directive").value;
    }

    if (this.formTarget.querySelector(".specific-cuts") != null) {
      for (var i = 0; i < 4; i++) {
        var quantity_id = "#order_entry_cut_quantity_" + (i + 1);
        var length_id = "#order_entry_cut_length_" + (i + 1);

        if (this.formTarget.querySelector(quantity_id) != null) {
          data["cuts"][i]["quantity"] = this.formTarget.querySelector(quantity_id).value;
        }

        if (this.formTarget.querySelector(length_id) != null) {
          data["cuts"][i]["length"] = this.formTarget.querySelector(length_id).value;
        }
      }
    }

    let pole_cut_1 = this.formTarget.querySelector("#order_entry_pole_cut_1");
    let pole_cut_degree_1 = this.formTarget.querySelector("#order_entry_pole_cut_1_degree");
    if (pole_cut_1 != null && pole_cut_1.value != null) {
      data["cuts"][0]["quantity"] = 1;
      data["cuts"][0]["length"] = pole_cut_1.value;
      if (pole_cut_degree_1 != null) {
        data["cuts"][0]["degree"] = pole_cut_degree_1.value;
      }
    }

    let pole_cut_2 = this.formTarget.querySelector("#order_entry_pole_cut_2");
    let pole_cut_degree_2 = this.formTarget.querySelector("#order_entry_pole_cut_2_degree");
    if (pole_cut_2 != null && pole_cut_2.value != null) {
      data["cuts"][1]["quantity"] = 1;
      data["cuts"][1]["length"] = pole_cut_2.value;
      if (pole_cut_degree_2 != null) {
        data["cuts"][1]["degree"] = pole_cut_degree_2.value;
      }
    }

    if (this.formTarget.querySelector("#order_entry_order_context").value == "cart") {
      data["cart_id"] = this.formTarget.querySelector("#order_entry_cart_id").value;
      if (data["cart_id"] == "new") {
        data["cart_id"] = "new_cart";
      }
      data["cart_item_id"] = this.formTarget.querySelector("#order_entry_cart_item_id").value;
      data["cart_name"] = this.formTarget.querySelector("#order_entry_new_cart_or_quote_name").value;
    }

    if (this.formTarget.querySelector("#order_entry_order_context").value == "quote") {
      data["quote_id"] = this.formTarget.querySelector("#order_entry_quote_id").value;
      if (data["quote_id"] == "new") {
        data["quote_id"] = "new_quote";
      }
      data["quote_item_id"] = this.formTarget.querySelector("#order_entry_quote_item_id").value;
      data["quote_name"] = this.formTarget.querySelector("#order_entry_new_cart_or_quote_name").value;
      data["account_id"] = this.formTarget.querySelector("#order_entry_new_quote_account_id").value;
    }

    if (this.formTarget.querySelector("#order_entry_banding").value == "true") {
      // {"product_type":"banded_hardware","color":"SILVER","zones":["A","C","E"]}
      data["extended_product_data"] = JSON.stringify({
        product_type: "banded_hardware",
        color: this.formTarget.querySelector("#order_entry_banding_finish").value,
        zones: this.formTarget.querySelector("#order_entry_banding_zones").value.split(","),
      });
    }

    if (this.formTarget.querySelector("#order_entry_customizations").value != null) {
      data["customizations"] = this.formTarget.querySelector("#order_entry_customizations").value;
    }

    if (this.hasHiddenBackingFieldTarget && this.hasHiddenFinishFieldTarget) {
      let backing = this.hiddenBackingFieldTarget.value;
      if (backing === "{}") {
        backing = "";
      }
      let finish = this.hiddenFinishFieldTarget.value;
      if (finish === "{}") {
        finish = "";
      }

      if (backing === "" && finish === "") {
        data["special_processing"] = null;
        return data;
      }

      if (backing !== "") {
        data["special_processing"]["backing"] = JSON.parse(backing);
      }
      if (finish !== "") {
        data["special_processing"]["easy_to_clean_finishes"] = JSON.parse(finish);
      }

      data["special_processing"] = JSON.stringify(data["special_processing"]);
    }

    return data;
  }

  submit_order(e) {
    let form_data = this.get_order_submission_data();

    let order_context = this.formTarget.querySelector("#order_entry_order_context").value;

    let url = order_context == "cart" ? "/api/cart-order" : "/api/add-to-quote";

    $.ajax(url, {
      method: "POST",
      async: true,
      cache: false,
      timeout: 10000,
      data: form_data,
      headers: { "X-CSRF-Token": $("meta[name=csrf-token]").prop("content") },
    })
      .done((objData) => {
        this.hideUJSSearchSpinner();
        $(document.body).trigger("ajax:complete");

        if (order_context == "cart") {
          if (!objData.boolSuccess) {
            toastr.error(objData.error);
            return;
          }

          var jCart = $("#order_entry_cart_id option:selected");

          addToCartAnalytics(form_data["sku"], form_data["quantity"], "regular-order");

          if (window.location.href.indexOf("my-account") > -1 || window.location.href.indexOf("checkouts") > -1) {
            window.location.reload();
          } else {
            toastr.success('Item added to cart "' + (form_data["cart_name"] != "" ? form_data["cart_name"] : jCart.text()) + '".');
            updateCartOrderCount("add");
          }

          document.body.removeChild(document.getElementById("order-entry-modal-overlay"));
        } else {
          if (!objData.boolSuccess) {
            toastr.error(objData.error);
            return;
          }

          var jQuote = $("#order_entry_quote_id option:selected");

          if (window.location.href.indexOf("my-account/quotes/new") > -1) {
            window.location.href = "/my-account/quotes/" + objData.quote_id;
          } else if (window.location.href.indexOf("my-account/quotes/") > -1) {
            window.location.reload();
          } else {
            toastr.success('Item added to quote "' + (form_data["quote_name"] != "" ? form_data["quote_name"] : jQuote.text()) + '".');
          }

          document.body.removeChild(document.getElementById("order-entry-modal-overlay"));
        }
      })
      .fail(() => {
        this.hideUJSSearchSpinner();
        $(document.body).trigger("ajax:complete");
        if (order_context == "cart") {
          toastr.error("Failed To Add Item To Selected Cart");
        } else {
          toastr.error("Failed To Add Item To Selected Quote");
        }
      });
  }

  toggle_pole_cut(e) {
    let clickedElement = e.srcElement.closest(".cut-selector");
    let inputValue = document.querySelector(`#order_entry_${clickedElement.getAttribute("data-degree")}`);
    let degreeValue = clickedElement.classList.contains("straight-cut") ? "90" : "45";
    let oppositeElementSelector = clickedElement.classList.contains("straight-cut") ? "miter-cut" : "straight-cut";
    let oppositeElement = clickedElement.parentElement.querySelector(`.${oppositeElementSelector}`);
    
    inputValue.value = degreeValue;
    clickedElement.classList.remove("tw-border", "tw-border-[#cccccc]");
    clickedElement.classList.add("tw-border-2", "tw-border-[#f9a700]");
    oppositeElement.classList.remove("tw-border-2", "tw-border-[#f9a700]");
    oppositeElement.classList.add("tw-border", "tw-border-[#cccccc]");
  }
}
