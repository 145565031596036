/* global $ */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable vars-on-top */
/* eslint-disable comma-dangle */
/* eslint-disable getter-return */
/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

const debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [
    'sidemark',
    'quantity',
    'toggleDescriptionRightArrow',
    'toggleDescriptionDownArrow',
    'retailPricingTab',
    'netPricingTab',
    'retailPricingContent',
    'netPricingContent'
  ];

  initialize() {
    this.quickshipImageZoom();
    window.addEventListener('resize', debounce(this.quickshipImageZoom, 200));
  }

  get ujsHelper() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'ujs-helper'
    );
  }

  get params() {
    return JSON.parse(this.element.dataset.params);
  }

  get rootElement() {
    return document.getElementById('quickshipPanelCustomizer');
  }

  get sidemark() {
    return this.sidemarkTarget.value;
  }

  get quantity() {
    if (this.hasQuantityTarget) {
      return parseInt(this.quantityTarget.value, 10);
    }
    return 1;
  }

  get state() {
    return {
      ...JSON.parse(this.element.dataset.state),
      sidemark: this.sidemark
    };
  }

  quickshipImageZoom() {
    var qsElevateZoomOptions = {
      zoomType: 'lens',
      lensShape: 'round',
      lensSize: 150,
      zoomLevel: 3,
      containLensZoom: true,
      borderColour: 'RGBA(245, 245, 245, 1)'
    };

    if ($(window).width() > 1024) {
      $('#qs-zoom').elevateZoom(qsElevateZoomOptions);
    }
  }

  toggleBaseMaterialDescription() {
    event.preventDefault();

    var content = this.rootElement.querySelector('.c-customizer-material-description');

    if (!content) { return; }

    content.classList.toggle('selected');

    if (this.hasToggleDescriptionRightArrowTarget) {
      this.toggleDescriptionRightArrowTarget.classList.toggle('hidden');
    }
    if (this.hasToggleDescriptionDownArrowTarget) {
      this.toggleDescriptionDownArrowTarget.classList.toggle('hidden');
    }

    this.quickshipImageZoom();
  }

  togglePricing(event) {
    event.preventDefault();

    if (this.hasRetailPricingTabTarget && this.hasNetPricingTabTarget) {
      this.retailPricingTabTarget.classList.toggle('active');
      this.netPricingTabTarget.classList.toggle('active');
      this.retailPricingContentTarget.classList.toggle('active');
      this.netPricingContentTarget.classList.toggle('active');
    }
  }

  setSelectedBaseMaterialGroup(event) {
    if (event.target.classList.contains('c-fabric-selector--no-click')) {
      return;
    }

    var target_group = event.currentTarget.dataset.selectedGroup;
    var selected_group = '';
    var base_material_groups = this.rootElement.querySelectorAll('.base-material-group');

    if (!base_material_groups) { return; }

    base_material_groups.forEach((el, i) => {
      var tabs = el.querySelectorAll('.c-accordion--tab');
      for (var i = 0; i < tabs.length; ++i) {
        if ((el.dataset.selectedGroup == target_group) && (tabs[i].classList.contains('selected'))) {
          selected_group = event.currentTarget.dataset.selectedGroup;
        }
      }
    });

    this.element.dataset.selectedBaseMaterialGroup = selected_group;
    this.showHideBaseMaterialGroups();
  }

  showHideBaseMaterialGroups() {
    var selected_group = this.rootElement.dataset.selectedBaseMaterialGroup;
    var base_material_groups = this.rootElement.querySelectorAll('.base-material-group');

    base_material_groups.forEach((el, i) => {
      var tabs = el.querySelectorAll('.c-accordion--tab');
      for (var i = 0; i < tabs.length; ++i) {
        tabs[i].classList.remove('open');
        if (el.dataset.selectedGroup == selected_group) {
          tabs[i].classList.add('open');
        }
      }
      var contents = el.querySelectorAll('.c-accordion--content');
      for (var i = 0; i < contents.length; ++i) {
        contents[i].classList.remove('open');
        if (el.dataset.selectedGroup == selected_group) {
          contents[i].classList.add('open');
        }
      }
    });
  }

  print(event) {
    event.preventDefault();

    window.print();
  }

  addToQuote(event) {
    event.preventDefault();

    window.Dialogs.addToQuote(this.state, this.quantity, 'QSP');
  }

  addToCart(event) {
    event.preventDefault();

    window.Dialogs.addToCart(this.state, this.quantity, 'QSP');
  }

  orderMemo(event) {
    event.preventDefault();

    const { memoId } = event.target.dataset;
    if (!memoId) {
      toastr.error('Memo not available for this product.');
    } else {
      window.Dialogs.orderMemo(memoId, false);
    }
  }

  saveCartItem(event) {
    event.preventDefault();

    const { cartId, cartItemId, checkout } = event.target.dataset;

    $.ajax({
      method: 'POST',
      url: '/finished-products/quickship-panels/update-cart-item',
      data: {
        state: this.state,
        sku: this.state['sku'],
        quantity: this.quantity,
        cart_item_id: cartItemId,
      },
      success: () => {
        if (checkout === 'true') {
          window.location.assign(`/checkouts/${cartId}`);
        } else {
          window.location.assign(`/my-account/cart/${cartId}`);
        }
      },
    });

    this.ujsHelper.showSpinner();
  }

  saveQuoteItem(event) {
    event.preventDefault();

    const { quoteId, quoteItemId } = event.target.dataset;

    $.ajax({
      method: 'POST',
      url: '/finished-products/quickship-panels/update-quote-item',
      data: {
        state: this.state,
        sku: this.state['sku'],
        quantity: this.quantity,
        quote_item_id: quoteItemId,
      },
      success: () => {
        window.location.assign(`/my-account/quotes/${quoteId}`);
      },
    });

    this.ujsHelper.showSpinner();
  }
}
