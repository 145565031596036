import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu', 'selection', 'selectionIcon', 'items'];

  connect() {
    this.close();
  }

  get closed() {
    return this.data.get('state') === 'closed';
  }

  closeIfOutsideMenu(event) {
    if (!this.menuTarget.contains(event.target)) {
      this.close();
    }
  }

  close() {
    this.data.set('state', 'closed');

    this.selectionTarget.classList.remove('open');
    this.selectionIconTarget.classList.remove('open');
    this.itemsTarget.classList.remove('open');
  }

  open() {
    this.data.set('state', 'open');

    this.selectionTarget.classList.add('open');
    this.selectionIconTarget.classList.add('open');
    this.itemsTarget.classList.add('open');
  }

  toggle() {
    if (this.closed) {
      this.open();
    } else {
      this.close();
    }
  }
}
