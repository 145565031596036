/* global $ */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable vars-on-top */
/* eslint-disable comma-dangle */
/* eslint-disable getter-return */
/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

const debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [
    'selectedProductId',
    'customWidth',
    'customLength',
    'rugPadAdded',
    'specialRugFinishAdded',
    'pricingAccountNumber',
    'quantity',
    'addToCart',
    'addToQuote',
    'showRetailPrice',
    'showNetPrice',
    'retailPricing',
    'netPricing'
  ];

  initialize() {
    this.updateForm = debounce(this.updateForm, 1000).bind(this);

    if (this.hasSpecialRugFinishAddedTarget) {
      this.specialRugFinishLabelSelected.classList.add('hidden');
    }

    this.rugPadAdded;
    this.validateCustomWidth();
    this.validateCustomLength();
  }

  get ujsHelper() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'ujs-helper'
    );
  }

  get rootElement() {
    return document.getElementById('rugCustomizer');
  }

  get productId() {
    return this.rootElement.dataset.productId;
  }

  get sizeGroup() {
    return this.rootElement.dataset.sizeGroup;
  }

  get selectedProductId() {
    if (this.hasSelectedProductIdTarget) { return this.selectedProductIdTarget.value; }
  }

  get customWidth() {
    if (this.hasCustomWidthTarget) { return this.customWidthTarget.value; }
  }

  get customLength() {
    if (this.hasCustomLengthTarget) { return this.customLengthTarget.value; }
  }

  get rugPadAddonLabelSelected() {
    return this.rootElement.querySelector('#rug-pad-addon-label-selected');
  }

  get rugPadAddonLabelDeselected() {
    return this.rootElement.querySelector('#rug-pad-addon-label-deselected');
  }

  get rugPadAdded() {
    var rug_pad_added = '';

    if (this.hasRugPadAddedTarget) {
      if (this.rugPadAddedTarget.checked) {
        rug_pad_added = this.rugPadAddedTarget.value;
      }

      if (rug_pad_added === 'yes') {
        this.rugPadAddonLabelSelected.classList.remove('hidden');
        this.rugPadAddonLabelDeselected.classList.add('hidden');
      } else {
        this.rugPadAddonLabelSelected.classList.add('hidden');
        this.rugPadAddonLabelDeselected.classList.remove('hidden');
      }
    }

    return rug_pad_added;
  }

  get specialRugFinishLabelSelected() {
    return this.rootElement.querySelector('#special-rug-finish-label-selected');
  }

  get specialRugFinishLabelDeselected() {
    return this.rootElement.querySelector('#special-rug-finish-label-deselected');
  }

  get specialRugFinishAdded() {
    var special_rug_finish_added = '';

    if (this.hasSpecialRugFinishAddedTarget) {
      if (this.specialRugFinishAddedTarget.checked) {
        special_rug_finish_added = this.specialRugFinishAddedTarget.value;
      }

      if (special_rug_finish_added === 'yes') {
        this.specialRugFinishLabelSelected.classList.remove('hidden');
        this.specialRugFinishLabelDeselected.classList.add('hidden');
      } else {
        this.specialRugFinishLabelSelected.classList.add('hidden');
        this.specialRugFinishLabelDeselected.classList.remove('hidden');
      }
    }

    return special_rug_finish_added;
  }

  get pricingAccountNumber() {
    if (this.hasPricingAccountNumberTarget) { return this.pricingAccountNumberTarget.value; }
  }

  get quantity() {
    if (this.hasQuantityTarget) { return this.quantityTarget.value; }
  }

  decreaseCustomWidth(event) {
    this.setCustomWidthLengthValue(this.customWidthTarget, 'min', event);
  }

  increaseCustomWidth(event) {
    this.setCustomWidthLengthValue(this.customWidthTarget, 'max', event);
  }

  decreaseCustomLength(event) {
    this.setCustomWidthLengthValue(this.customLengthTarget, 'min', event);
  }

  increaseCustomLength(event) {
    this.setCustomWidthLengthValue(this.customLengthTarget, 'max', event);
  }

  setCustomWidthLengthValue(target, type, event) {
    var new_val = parseFloat(target.value);

    if (type === 'min') { new_val -= 1; }
    if (type === 'max') { new_val += 1; }

    target.value = new_val.toFixed(0);

    if (target.name.includes('width')) {
      this.validateCustomWidth(event);
    }

    if (target.name.includes('length')) {
      this.validateCustomLength(event);
    }
  }

  validateCustomWidth(event) {
    var widthElement = document.getElementById('custom_width');
    var min_width = parseFloat(this.element.getAttribute('data-min-custom-width'));
    var max_width = parseFloat(this.element.getAttribute('data-max-custom-width'));
    var new_val = parseFloat(this.customWidthTarget.value);
    var toggle = '';

    if (isNaN(new_val)) { new_val = 0; }
    if (new_val <= min_width) { new_val = min_width; toggle = 'minus'; }
    if (new_val >= max_width) { new_val = max_width; toggle = 'plus'; }

    this.customWidthTarget.value = new_val.toFixed(0);
    this.updateForm();
    this.toggleCustomInputButtons((event || widthElement), toggle);
  }

  validateCustomLength(event) {
    var lengthElement = document.getElementById('custom_length');
    var min_length = parseFloat(this.element.getAttribute('data-min-custom-length'));
    var max_length = parseFloat(this.element.getAttribute('data-max-custom-length'));
    var new_val = parseFloat(this.customLengthTarget.value);
    var toggle = '';

    if (isNaN(new_val)) { new_val = 0; }
    if (new_val <= min_length) { new_val = min_length; toggle = 'minus'; }
    if (new_val >= max_length) { new_val = max_length; toggle = 'plus'; }

    this.customLengthTarget.value = new_val.toFixed(0);
    this.updateForm();
    this.toggleCustomInputButtons((event || lengthElement), toggle);
  }

  toggleCustomInputButtons(event, toggle) {
    var wrapper = (event.currentTarget || event).closest('.qty-input');
    if (!wrapper) { return; }

    var btn_minus = wrapper.querySelector('.fa-minus-circle');
    var btn_plus = wrapper.querySelector('.fa-plus-circle');
    btn_minus.classList.remove('disabled');
    btn_plus.classList.remove('disabled');

    if (toggle === 'minus') { btn_minus.classList.add('disabled'); }
    if (toggle === 'plus') { btn_plus.classList.add('disabled'); }
  }

  disableModalDialogTriggers() {
    if (this.hasAddToCartTarget) {
      this.addToCartTarget.setAttribute('disabled', 'disabled');
    }
    if (this.hasAddToQuoteTarget) {
      this.addToQuoteTarget.setAttribute('disabled', 'disabled');
    }
  }

  enableModalDialogTriggers() {
    if (this.hasAddToCartTarget) {
      this.addToCartTarget.removeAttribute('disabled');
    }
    if (this.hasAddToQuoteTarget) {
      this.addToQuoteTarget.removeAttribute('disabled');
    }
  }

  updateForm() {
    this.disableModalDialogTriggers();

    $.ajax({
      method: 'GET',
      url: '/product/' + this.productId + '/rug_customizations/update_form',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
      },
      data: {
        size_group: this.sizeGroup,
        selected_product_id: this.selectedProductId,
        custom_width: this.customWidth,
        custom_length: this.customLength,
        rug_pad_added: this.rugPadAdded,
        special_rug_finish_added: this.specialRugFinishAdded,
        quantity: this.quantity
      },
      success: () => {
        this.enableModalDialogTriggers();
      }
    });

    this.ujsHelper.showSpinner();
  }

  switchPricingAccount(event) {
    event.preventDefault();

    $.ajax({
      method: 'PATCH',
      url: '/product/' + this.productId + '/rug_customizations/switch_pricing_account',
      data: {
        size_group: this.sizeGroup,
        selected_product_id: this.selectedProductId,
        custom_width: this.customWidth,
        custom_length: this.customLength,
        rug_pad_added: this.rugPadAdded,
        special_rug_finish_added: this.specialRugFinishAdded,
        quantity: this.quantity,
        user: {
          settings: {
            default_account: this.pricingAccountNumber
          }
        }
      }
    });

    this.ujsHelper.showSpinner();
  }

  showRetailPrice() {
    this.netPricingTarget.classList.add('hidden');
    this.retailPricingTarget.classList.remove('hidden');
    this.showRetailPriceTarget.classList.remove('gray-text');
    this.showNetPriceTarget.classList.add('gray-text');
  }

  showNetPrice() {
    this.netPricingTarget.classList.remove('hidden');
    this.retailPricingTarget.classList.add('hidden');
    this.showRetailPriceTarget.classList.add('gray-text');
    this.showNetPriceTarget.classList.remove('gray-text');
  }
}
