import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';

const FABRIC = 'fabric';
const TRIMMING = 'trimming';
const LEATHER = 'leather';

const SOLID_PATTERN = 'solid';
const REPEATED_PATTERN = 'repeated';

export default class extends Controller {
  static targets = [
    'errors',
    'materialType',
    'fixedMaterialType',
    'patternType',
    'repeats',
    'quantity',
    'disclaimer',
    'description',
    'horizontalRepeat',
    'verticalRepeat',
    'requiredField',
    'applyButton',
  ];

  connect() {
    if (this.hasErrorsTarget) {
      window.scrollTo({
        top: this.errorsTarget.offsetTop - 200,
        behavior: 'smooth',
      });
    }

    this.update();
  }

  get keyPrefix() {
    return this.data.get('key-prefix');
  }

  get updateUrl() {
    return this.data.get('update-url');
  }

  get materialType() {
    if (!this.hasMaterialTypeTarget) {
      if (this.hasFixedMaterialTypeTarget) {
        return this.fixedMaterialTypeTarget.value;
      }
      return null;
    }
    return checkedValue(this.materialTypeTarget);
  }

  get patternType() {
    if (!this.hasPatternTypeTarget) {
      return null;
    }
    return checkedValue(this.patternTypeTarget);
  }

  get showPatternType() {
    return this.materialType === FABRIC;
  }

  get showRepeats() {
    return this.showPatternType && this.patternType === REPEATED_PATTERN;
  }

  get showDescription() {
    if (!this.hasMaterialTypeTarget && !this.hasFixedMaterialTypeTarget) {
      return true;
    }

    if (this.showPatternType) {
      return this.patternType != null;
    }
    return this.materialType != null;
  }

  get horizontalRepeat() {
    if (!this.hasHorizontalRepeatTarget) {
      return null;
    }
    return repeatValue(this.horizontalRepeatTarget);
  }

  get verticalRepeat() {
    if (!this.hasVerticalRepeatTarget) {
      return null;
    }
    return repeatValue(this.verticalRepeatTarget);
  }

  get applyButtonDisabled() {
    if (this.showRepeats) {
      if (this.horizontalRepeat == null && this.verticalRepeat == null) {
        return true;
      }
    }

    for (let i = 0; i < this.requiredFieldTargets.length; i++) {
      if (this.requiredFieldTargets[i].value === '') {
        return true;
      }
    }

    return false;
  }

  update(event) {
    if (this.hasPatternTypeTarget) {
      this.patternTypeTarget.classList.toggle('hidden', !this.showPatternType);
    }

    if (this.hasRepeatsTarget) {
      this.repeatsTarget.classList.toggle('hidden', !this.showRepeats);
    }

    if (this.hasDisclaimerTarget) {
      this.disclaimerTarget.classList.toggle('hidden', this.materialType === LEATHER);
    }

    this.descriptionTarget.classList.toggle('hidden', !this.showDescription);

    if (event != null) {
      this.applyButtonTarget.disabled = this.applyButtonDisabled;
    }

    this.updateQuantity();
  }

  updateQuantity = debounce(() => {
    this.quantityTarget.classList.add('loading');

    $.ajax({
      url: this.updateUrl,
      data: {
        key_prefix: this.keyPrefix,
        updates: {
          [this.keyPrefix]: {
            material_type: this.materialType,
            pattern_type: this.patternType,
            horizontal_repeat: this.horizontalRepeat,
            vertical_repeat: this.verticalRepeat,
          },
        },
      },
    });
  }, 500);

  submit() {
    this.applyButtonTarget.disabled = true;
  }
}

function checkedValue(el) {
  const input = el.querySelector('input:checked');
  if (!input) {
    return null;
  }
  return input.value;
}

function repeatValue(el) {
  const value = parseFloat(el.value);
  if (Number.isNaN(value) || value <= 0) {
    return null;
  }
  return value;
}
