import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'priceListHeader',
    'brandGrid',
    'returnLink'
  ];

  initialize() {
    this.selectedBrand = '';
  }

  showBrand(event) {
    const button = event.currentTarget;
    const brand = button.getAttribute('data-brand');

    this.selectedBrand = brand;
    
    this.priceListHeaderTarget.innerHTML = brand;
    this.brandGridTarget.classList.add('hidden');
    this.returnLinkTarget.classList.remove('hidden');
    document.querySelector(`section.price-list-container[data-brand="${brand}"]`).classList.remove('hidden');
  }

  showBrandGrid() {
    this.priceListHeaderTarget.innerHTML = 'Price Lists by Brands and Products';
    this.brandGridTarget.classList.remove('hidden');
    this.returnLinkTarget.classList.add('hidden');
    document.querySelector(`section.price-list-container[data-brand="${this.selectedBrand}"]`).classList.add('hidden');
    this.selectedBrand = '';
  }

  
}
