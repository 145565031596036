import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'furnitureCategories',
    'furnitureCategoriesForm',
    'furnitureGroups',
    'furnitureGroupsForm'
  ];

  get_group_dropdown_items(){
    $(this.furnitureCategoriesFormTarget).submit();
  }

  get_furniture_items(){
    $(this.furnitureGroupsFormTarget).submit();
  }
}
