import { Controller } from 'stimulus';

import Flickity from 'flickity';

export default class extends Controller {
  connect() {
    this.reinitialize();
  }

  disconnect() {
    this.flkty.destroy();
  }

  reinitialize() {
    if (this.flkty != null) {
      this.flkty.destroy();
    }
    
    var options = {}
    if(options != null) {
      var options = JSON.parse(this.element.getAttribute('data-flickity-options'))
    }

    this.flkty = new Flickity(this.element, options);
  }
}
