import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "quoteTemplateSelect",
    "quoteForm",
    "billToStreet",
    "billToZipCode",
    "billToCity",
    "billToState",
    "billToCountry",
    "shipToCheckbox",
    "shipToStreet",
    "shipToZipCode",
    "shipToCity",
    "shipToState",
    "shipToCountry",
    "cfaCheckbox",
    "cfaStreet",
    "cfaZipCode",
    "cfaCity",
    "cfaState",
    "cfaCountry",
    "shipToSection",
    "cfaSection",
    "pdfLink",
    "customerInfo",
    "quickSearch",
    "cfaCheckbox",
    "cfaNotice",
    "billToCompanyName",
    "shipToCompanyName",
    "cfaCompanyName"
  ];

  initialize () {
    this.formFields = [
      "create_quote_name",
      "quote_account_select",
      "quote_cfa_address_attributes_city",
      "quote_cfa_address_attributes_country",
      "quote_cfa_address_attributes_state",
      "quote_cfa_address_attributes_street1",
      "quote_cfa_address_attributes_zip",
      "quote_customer_address_attributes_city",
      "quote_customer_address_attributes_country",
      "quote_customer_address_attributes_state",
      "quote_customer_address_attributes_street1",
      "quote_customer_address_attributes_zip",
      "quote_form_account_number",
      "quote_form_business_name",
      "quote_notes",
      "quote_requested_by_email",
      "quote_requested_by_name",
      "quote_requested_by_phone",
      "quote_same_as_bill_to",
      "quote_same_as_ship_to",
      "quote_ship_to_address_attributes_city",
      "quote_ship_to_address_attributes_country",
      "quote_ship_to_address_attributes_state",
      "quote_ship_to_address_attributes_street1",
      "quote_ship_to_address_attributes_zip",
      "quote_template_select",
      "quote_bill_to_company_name",
      "quote_ship_to_company_name",
      "quote_cfa_company_name"
    ];

    this.cfaTargets = [
      this.cfaStreetTarget,
      this.cfaZipCodeTarget,
      this.cfaCityTarget,
      this.cfaStateTarget,
      this.cfaCountryTarget
    ];

    window.addEventListener('DOMContentLoaded', (event) => {
      localStorage.removeItem('quoteForm'); 
      let form = [];
      this.formFields.forEach((element) => {
        form.push(document.getElementById(element).value);
      });
      localStorage.setItem('quoteForm', JSON.stringify(form));

      this.returnUrl = window.location.href;
      if(window.location.href.includes("templateId")) {
        this.returnUrl = this.returnUrl.replace(/\?templateId=(.*$)/g, "");
      }
      
      if(document.querySelector('.quote-button-edit') != null) {
        this.setEditLink();
      }
    });

    this.quickSearchTarget.addEventListener("keypress", function(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });

    if (this.hasCfaNoticeTarget) {
      document.addEventListener('keyup', (event) => {
        let isOneFilled = false;
        this.cfaTargets.forEach((element) => {
          if (element.value != "") {
            isOneFilled = true;
            return;
          }
        });
        if (isOneFilled) {
          this.cfaNoticeTarget.classList.remove('hidden');
        } else {
          this.cfaNoticeTarget.classList.add('hidden');
        }
      });

      this.copyToCFAAddress();
    }

    document.querySelectorAll(".show-more-details").forEach((element) => {
      element.addEventListener("click", (e) => {
        e.preventDefault();
        $.ajax(e.target.getAttribute('data-url'), {
          method: "GET",
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
            contentType: 'text/javascript'
          },
          dataType: "script"
        }).fail((e) => {
          toastr.error(e.responseText);
        })
      });
    });

    document.querySelector("#duplicate-quote-btn-container").addEventListener("click", (e) => {
      e.preventDefault();
      $.ajax({
        type: "GET",
        url: '/modal/new',
        dataType: "script",
        contentType: 'application/json',
        data: e.target.getAttribute("data-params"),
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
        }
      }).fail((e) => {
        toastr.error(e.responseJSON.errors);
      });
    });
  }

  selectTemplate() {
    switch (this.quoteTemplateSelectTarget.value) {
      case "no-template":
        this.quoteFormTarget.classList.add("hidden");
        break;
      case "new-template":
        this.loadNewTemplate();
        break;
      default:
        this.loadSavedTemplate(this.quoteTemplateSelectTarget.value);
    }
  }

  setEditLink() {
    let editLink = document.querySelector('.quote-button-edit').getAttribute('href');
    document.querySelector('.quote-button-edit').setAttribute('href', `${editLink}?return=${this.returnUrl}`);
  }

  loadNewTemplate() {
    window.location = `/my-account/quote-templates/new?return=${this.returnUrl}`;
  }

  loadSavedTemplate(value) {
    $.ajax({
      type: "GET",
      url: `/my-account/quote-templates/find_template/${value}`,
      dataType: "script",
      success: () => {
        this.quoteFormTarget.classList.remove("hidden");
        this.setEditLink();
      }
    });
  }

  copyToShipToAddress() {
    let shipToTargets = [
      this.shipToStreetTarget,
      this.shipToZipCodeTarget,
      this.shipToCityTarget,
      this.shipToStateTarget,
      this.shipToCountryTarget
    ];
    if (this.shipToCheckboxTarget.checked) {
      this.copyAddress(shipToTargets);
      this.shipToSectionTarget.classList.add('hidden');
    } else {
      this.resetFormFields(shipToTargets);
      this.shipToSectionTarget.classList.remove('hidden');
    }
  }

  copyToCFAAddress() {
    if (this.cfaCheckboxTarget.checked) {
      this.copyAddress(this.cfaTargets);
      this.cfaSectionTarget.classList.add('hidden');
      this.customerInfoTarget.classList.add('pt-4');
      this.cfaNoticeTarget.classList.remove('hidden');
    } else {
      this.resetFormFields(this.cfaTargets);
      this.cfaSectionTarget.classList.remove('hidden');
      this.customerInfoTarget.classList.remove('pt-4');
      this.cfaNoticeTarget.classList.add('hidden');
    }
  }

  copyAddress(arr) {
    arr.forEach((element, index) => {
      element.setAttribute("disabled", true);
      element.classList.add("disabled");
      element.value = "";
    });
  }

  resetFormFields(arr) {
    arr.forEach((element, index) => {
      element.removeAttribute("disabled");
      element.classList.remove("disabled");
    });
  }

  saveAndPrint(event) {
    event.preventDefault();
    toastr.info('Saving Quote and Generating PDF');
    let url = event.target.getAttribute('data-href') != null ? event.target.getAttribute('data-href') : event.target.getAttribute("href");
    if (document.getElementById('destination_zip') !== null && document.getElementById('destination_zip').value !== "") {
      url = url.replace("destination_zip=", `destination_zip=${document.getElementById('destination_zip').value}`);
      let ship_via = 'UPS';
      if ($('[name=regular_shipping]:checked').length > 0) {
        ship_via = document.querySelector('[name=regular_shipping]:checked').value;
      }
      url = url.replace("ship_via=", `ship_via=${ship_via}`);
    }
    this.pdfLinkTarget.setAttribute("value", url);
    let formData = $('#edit-quote-form').serializeArray();
    $.ajax({
      type: "POST",
      url: `/my-account/quotes/${document.querySelector('.quote-container').getAttribute('data-quote-id')}`,
      dataType: "script",
      data: formData
    });
  }

  saveForm() {
    let originalForm = JSON.parse(localStorage.getItem('quoteForm'));
    if(this.quoteTemplateSelectTarget.value !== 'no-template') {
      this.formFields.forEach((element, index) => {
        if (document.getElementById(element).value !== originalForm[index]) {
          toastr.info('Saving Form');
          this.quickSearchTarget.blur();
          this.quickSearchTarget.setAttribute("disabled", true);
          document.getElementById('edit-quote-form').submit();
          return;
        }
      });
    }  
  }

  validateForm(event) {
    event.preventDefault();
    toastr.info('Saving Quote');
    let form = document.getElementById('edit-quote-form');
    if (form.querySelector('[name="destination_zip"]') !== null) {
      form.querySelector('[name="destination_zip"]').remove();
    }
    form.submit();
  }
  
  printExpired() {
    let quoteId = document.querySelector('.quote-container').getAttribute('data-quote-id');
    window.open("/my-account/quotes/" + quoteId + ".pdf", quoteId + ".pdf")
  }

  expiredMessage(event) {
    event.preventDefault();
    toastr.error("You cannot edit an expired or processed quote.");
  }
}
