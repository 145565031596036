/* global $ */
/* eslint-disable camelcase */
/* eslint-disable comma-dangle */
/* eslint-disable prefer-template */
/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

const debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [
    'destinationZipCode',
    'regularShipping'
  ];

  initialize() {
    this.updateForm = debounce(this.updateForm, 1000).bind(this);
    if (this.freightEstimateFormContainer) { this.freightEstimateFormContainer.classList.add('hidden'); }
  }

  get ujsHelper() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'ujs-helper'
    );
  }

  get rootElement() {
    return document.getElementById('quoteFreightEstimator');
  }

  get quoteId() {
    return this.rootElement.dataset.quoteId;
  }

  get destinationZipCode() {
    if (this.hasDestinationZipCodeTarget) { return this.destinationZipCodeTarget.value; }
    return '';
  }

  get regularShipping() {
    let ship_via = 'UPS';
    if ($('[name=regular_shipping]:checked').length > 0) {
      ship_via = $('[name=regular_shipping]:checked').val();
    }
    return ship_via;
  }

  get freightEstimateFormContainer() {
    return this.rootElement.querySelector('.freight-estimate-form-container');
  }

  get freightItemsList() {
    return this.rootElement.querySelector('.freight-estimate-items-wrapper');
  }

  get calculateFreightLinks() {
    return this.rootElement.querySelector('.calculate-freight-link');
  }

  get recalculateFreightLink() {
    return this.rootElement.querySelector('.recalculate-freight-link');
  }

  get hideCalculateFreightLinks() {
    return this.rootElement.querySelector('.hide-calculate-freight-links');
  }

  showFreightCalculator(event) {
    event.preventDefault();
    if (this.freightEstimateFormContainer) { this.freightEstimateFormContainer.classList.remove('hidden'); }
    if (this.freightItemsList) { this.freightItemsList.classList.add('hidden'); }
    if (this.hideCalculateFreightLinks) { this.hideCalculateFreightLinks.classList.remove('hidden'); }
    if (this.calculateFreightLinks) { this.calculateFreightLinks.classList.add('hidden'); }
    if (this.recalculateFreightLink) { this.recalculateFreightLink.classList.add('hidden'); }
  }

  hideFreightCalculator(event) {
    event.preventDefault();
    if (this.freightEstimateFormContainer) { this.freightEstimateFormContainer.classList.add('hidden'); }
    if (this.hideCalculateFreightLinks) { this.hideCalculateFreightLinks.classList.add('hidden'); }
    if (this.calculateFreightLinks) { this.calculateFreightLinks.classList.remove('hidden'); }
    if (this.recalculateFreightLink) { this.recalculateFreightLink.classList.remove('hidden'); }
  }

  updateForm(event) {
    event.preventDefault();

    $.ajax({
      method: 'POST',
      url: '/my-account/quotes/' + this.quoteId + '/update_footer',
      data: {
        ship_via: this.regularShipping,
        destination_zip: this.destinationZipCode
      },
      success: () => {
      }
    });

    this.ujsHelper.showSpinner();
  }
}
