import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    window.addEventListener('DOMContentLoaded', (event) => {
      document.querySelectorAll(".duplicate-cart-button").forEach((element) => {
        element.addEventListener("click", (e) => {
          e.preventDefault();
          $.ajax('/modal/new', {
            method: "GET",
            dataType: "script",
            contentType: 'application/json',
            data: e.target.getAttribute("data-params"),
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
            }
          }).fail((e) => {
            toastr.error(e.responseJSON.errors);
          });
        });
      });
    });
  }
  activateCart(e) {
    let cart = $(e.currentTarget).closest('div[cart_id]');
    
    toastr.info('Activating Cart');

    $.ajax(('/api/activate-cart/' + cart.attr('cart_id')), {
      method: 'POST',
      async: true,
      cache: false,
      timeout: 7000,
      headers: { 'X-CSRF-Token': $('meta[name=csrf-token]').prop('content') },
    }).success(() => {
      window.location.reload();
    }).fail(() => {
      toastr.error('Failed To Activate Cart')
    });
  }
}