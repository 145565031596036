import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    document.querySelectorAll(".quote-approved").forEach((element) => {
      element.addEventListener("change", () => {
        let approvalType = element.checked ? "approved" : "disapproved";
        let quoteId = element.getAttribute("quote_id");
        element.setAttribute("disabled", true);

        $.ajax(`/my-account/quotes/${quoteId}/${approvalType}`, {
          method: "POST",
          cache: false,
          async: true
        }).then(() => {
          approvalType == "approved" ? toastr.success("Quote approved") : toastr.success("Quote unapproved");
          element.removeAttribute("disabled");
        }).fail((e) => {
          element.checked ? element.checked = false : element.checked = true;
          toastr.error(e.responseJSON.errors);
        });
      }, false);
    });

    document.querySelectorAll(".quote-index__delete-button, .quote-index__duplicate-button").forEach((element) => {
      element.addEventListener("click", (e) => {
        e.preventDefault();
        $.ajax('/modal/new', {
          method: "GET",
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
            contentType: 'text/javascript'
          },
          dataType: "script",
          data: element.getAttribute("data-params")
        }).fail((e) => {
          toastr.error(e.responseJSON.errors);
        })
      });
    });
  }
}
