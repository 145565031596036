import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "templateName",
    "saveTemplateButton"
  ];

  checkTemplateName() {
    if (this.templateNameTarget.value !== "") {
      this.saveTemplateButtonTarget.classList.add('quote-button-lrg');
      this.saveTemplateButtonTarget.classList.remove('save-quote-template-button');
      this.saveTemplateButtonTarget.removeAttribute("disabled");
    } else {
      this.saveTemplateButtonTarget.classList.remove('quote-button-lrg');
      this.saveTemplateButtonTarget.classList.add('save-quote-template-button');
      this.saveTemplateButtonTarget.setAttribute("disabled", true);
    }
  }
}