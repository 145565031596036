import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  scrollTop(e) {
    if (e != null) e.preventDefault();

    this.contentTarget.scrollTo({ top: 0, behavior: "smooth" });
  }

  scrollBottom(e) {
    if (e != null) e.preventDefault();

    this.contentTarget.scrollTo({
      top: this.maxScrollTop,
      behavior: "smooth",
    });
  }

  scrollLeft(e) {
    if (e != null) e.preventDefault();

    this.contentTarget.scrollTo({ left: 0, behavior: "smooth" });
  }

  scrollRight(e) {
    if (e != null) e.preventDefault();

    this.contentTarget.scrollTo({
      left: this.maxScrollLeft,
      behavior: "smooth",
    });
  }

  get maxScrollTop() {
    return this.contentTarget.scrollHeight - this.contentTarget.offsetHeight;
  }

  get maxScrollLeft() {
    return this.contentTarget.scrollWidth - this.contentTarget.offsetWidth;
  }
}
