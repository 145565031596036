import { Controller } from 'stimulus';
import qs from 'qs';

export default class extends Controller {
  static targets = ['specialInstructions', 'quantity'];

  get ujsHelper() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'ujs-helper'
    );
  }

  get specialInstructions() {
    return this.specialInstructionsTarget.value;
  }

  get state() {
    return {
      ...JSON.parse(this.element.dataset.state),
      specialInstructions: this.specialInstructions,
    };
  }

  get quantity() {
    return this.quantityTarget.value;
  }

  print(event) {
    event.preventDefault();
    document.title = this.element.getAttribute('data-product-title');
    window.print();
    document.title = 'Fabricut';
  }

  addToQuote(event) {
    event.preventDefault();

    window.Dialogs.addToQuote(this.state, this.quantity, 'furniture');
  }

  addToCart(event) {
    event.preventDefault();

    window.Dialogs.addToCart(this.state, this.quantity, 'furniture');
  }

  orderMemo(event) {
    event.preventDefault();

    const { memoId } = event.target.dataset;

    window.Dialogs.orderMemo(memoId, false);
  }

  orderWoodChipMemo(event) {
    event.preventDefault();

    const { woodChipId } = event.target.dataset;

    window.Dialogs.orderMemo(woodChipId, true);
  }

  saveCartItem(event) {
    event.preventDefault();

    const { cartId, cartItemId, checkout } = event.target.dataset;

    $.ajax({
      method: 'POST',
      url: '/furniture/update-cart-item',
      data: {
        state: this.state,
        quantity: this.quantity,
        cart_item_id: cartItemId,
      },
      success: () => {
        if (checkout === 'true') {
          window.location.assign(`/checkouts/${cartId}`);
        } else {
          window.location.assign(`/my-account/cart/${cartId}`);
        }
      },
    });

    this.ujsHelper.showSpinner();
  }

  saveQuoteItem(event) {
    event.preventDefault();

    const { quoteId, quoteItemId } = event.target.dataset;

    $.ajax({
      method: 'POST',
      url: '/furniture/update-quote-item',
      data: {
        state: this.state,
        quantity: this.quantity,
        quote_item_id: quoteItemId,
      },
      success: () => {
        window.location.assign(`/my-account/quotes/${quoteId}`);
      },
    });

    this.ujsHelper.showSpinner();
  }
}
