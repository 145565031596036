/* global $ */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable vars-on-top */
/* eslint-disable comma-dangle */
/* eslint-disable getter-return */
/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
import { Controller } from 'stimulus';

const debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = [
    'sidemark',
    'quantity',
    'toggleFaceDescriptionRightArrow',
    'toggleFaceDescriptionDownArrow',
    'toggleBackDescriptionRightArrow',
    'toggleBackDescriptionDownArrow',
    'retailPricingTab',
    'netPricingTab',
    'retailPricingContent',
    'netPricingContent',
    'quantityForm'
  ];

  initialize() {
    this.submitQtyForm = debounce(this.submitQtyForm, 1000).bind(this);

    this.materialImageZoom();
    window.addEventListener('resize', debounce(this.materialImageZoom, 200));
  }

  get ujsHelper() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'ujs-helper'
    );
  }

  get params() {
    return JSON.parse(this.element.dataset.params);
  }

  get rootElement() {
    return document.getElementById('pillowCustomizer');
  }

  get sidemark() {
    return this.sidemarkTarget.value;
  }

  get quantity() {
    if (this.hasQuantityTarget) {
      return parseInt(this.quantityTarget.value, 10);
    }
    return 1;
  }

  get state() {
    return {
      ...JSON.parse(this.element.dataset.state),
      sidemark: this.sidemark
    };
  }

  materialImageZoom() {
    var qsElevateZoomOptions = {
      zoomType: 'lens',
      lensShape: 'round',
      lensSize: 150,
      zoomLevel: 3,
      containLensZoom: true,
      borderColour: 'RGBA(245, 245, 245, 1)'
    };

    if ($(window).width() > 1024) {
      $('#material-zoom1').elevateZoom(qsElevateZoomOptions);
      $('#material-zoom2').elevateZoom(qsElevateZoomOptions);
    }
  }

  toggleFaceMaterialDescription() {
    event.preventDefault();

    var content = this.rootElement.querySelector('.c-customizer-face-material-description');

    if (!content) { return; }

    content.classList.toggle('selected');

    if (this.hasToggleFaceDescriptionRightArrowTarget) {
      this.toggleFaceDescriptionRightArrowTarget.classList.toggle('hidden');
    }
    if (this.hasToggleFaceDescriptionDownArrowTarget) {
      this.toggleFaceDescriptionDownArrowTarget.classList.toggle('hidden');
    }

    this.materialImageZoom();
  }

  toggleBackMaterialDescription() {
    event.preventDefault();

    var content = this.rootElement.querySelector('.c-customizer-back-material-description');

    if (!content) { return; }

    content.classList.toggle('selected');

    if (this.hasToggleBackDescriptionRightArrowTarget) {
      this.toggleBackDescriptionRightArrowTarget.classList.toggle('hidden');
    }
    if (this.hasToggleBackDescriptionDownArrowTarget) {
      this.toggleBackDescriptionDownArrowTarget.classList.toggle('hidden');
    }

    this.materialImageZoom();
  }

  decreaseQuantity(event) {
    this.setQuantityValue(this.quantityTarget, 'min', event);
  }

  increaseQuantity(event) {
    this.setQuantityValue(this.quantityTarget, 'max', event);
  }

  setQuantityValue(target, type, event) {
    var new_val = parseFloat(target.value);

    if (type === 'min') { new_val -= 1; }
    if (type === 'max') { new_val += 1; }

    target.value = new_val.toFixed(0);

    var inputElement = this.quantityTarget;
    var min_quantity = 1;
    var max_quantity = 10000;
    var new_val = parseFloat(this.quantityTarget.value);
    var toggle = '';

    if (isNaN(new_val)) { new_val = 0; }
    if (new_val <= min_quantity) { new_val = min_quantity; toggle = 'minus'; }
    if (new_val >= max_quantity) { new_val = max_quantity; toggle = 'plus'; }

    this.quantityTarget.value = new_val.toFixed(0);

    this.toggleQuantityInputButtons((event || inputElement), toggle);

    this.submitQtyForm();
  }

  submitQtyForm() {
    $(this.quantityFormTarget).submit();
  }

  validateQuantity(event) {
    var inputElement = this.quantityTarget;
    var min_quantity = 1;
    var max_quantity = 10000;
    var new_val = parseFloat(this.quantityTarget.value);
    var toggle = '';

    if (isNaN(new_val)) { new_val = 0; }
    if (new_val <= min_quantity) { new_val = min_quantity; toggle = 'minus'; }
    if (new_val >= max_quantity) { new_val = max_quantity; toggle = 'plus'; }

    this.quantityTarget.value = new_val.toFixed(0);

    this.toggleQuantityInputButtons((event || inputElement), toggle);

    this.submitQtyForm();
  }

  toggleQuantityInputButtons(event, toggle) {
    var wrapper = (event.currentTarget || event).closest('.qty-input');
    if (!wrapper) { return; }

    var btn_minus = wrapper.querySelector('.fa-minus-circle');
    var btn_plus = wrapper.querySelector('.fa-plus-circle');
    btn_minus.classList.remove('disabled');
    btn_plus.classList.remove('disabled');

    if (toggle === 'minus') { btn_minus.classList.add('disabled'); }
    if (toggle === 'plus') { btn_plus.classList.add('disabled'); }
  }

  togglePricing(event) {
    event.preventDefault();

    if (this.hasRetailPricingTabTarget && this.hasNetPricingTabTarget) {
      this.retailPricingTabTarget.classList.toggle('active');
      this.netPricingTabTarget.classList.toggle('active');
      this.retailPricingContentTarget.classList.toggle('active');
      this.netPricingContentTarget.classList.toggle('active');
    }
  }

  print(event) {
    event.preventDefault();

    window.print();
  }

  addToQuote(event) {
    event.preventDefault();

    window.Dialogs.addToQuote(this.state, this.quantity, 'pillow');
  }

  addToCart(event) {
    event.preventDefault();

    window.Dialogs.addToCart(this.state, this.quantity, 'pillow');
  }

  orderMemo(event) {
    event.preventDefault();
    let faceMaterialId = event.target.getAttribute('data-face-material-id');
    let backMaterialId = event.target.getAttribute('data-back-material-id');

    if(backMaterialId == faceMaterialId) {
      backMaterialId = null;
    }

    window.Dialogs.orderPillowMemos(faceMaterialId, backMaterialId);
  }

  saveCartItem(event) {
    event.preventDefault();

    const { cartId, cartItemId, checkout } = event.target.dataset;

    $.ajax({
      method: 'POST',
      url: '/pillows/update-cart-item',
      data: {
        state: this.state,
        quantity: this.quantity,
        cart_item_id: cartItemId,
      },
      success: () => {
        if (checkout === 'true') {
          window.location.assign(`/checkouts/${cartId}`);
        } else {
          window.location.assign(`/my-account/cart/${cartId}`);
        }
      },
    });

    this.ujsHelper.showSpinner();
  }

  saveQuoteItem(event) {
    event.preventDefault();

    const { quoteId, quoteItemId } = event.target.dataset;

    $.ajax({
      method: 'POST',
      url: '/pillows/update-quote-item',
      data: {
        state: this.state,
        quantity: this.quantity,
        quote_item_id: quoteItemId,
      },
      success: () => {
        window.location.assign(`/my-account/quotes/${quoteId}`);
      },
    });

    this.ujsHelper.showSpinner();
  }
}
