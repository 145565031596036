import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'container',
    'content',
    'btn_open',
    'btn_close',
    'image',
  ];

  open() {
    this.containerTarget.setAttribute('style', `width: ${this.imageTarget.width}px; height: ${this.imageTarget.height}px`);
    this.contentTarget.classList.add('show-lightbox');
  }

  close() {
    this.contentTarget.classList.remove('show-lightbox');
    this.containerTarget.removeAttribute('style');
  }

  closeOnEscape(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
}
