import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["spinner", "loading"];

  connect() {
    $(document.body).on("ajax:beforeSend", () => {
      this.showSpinner();
    });

    $(document.body).on("ajax:complete", () => {
      this.hideSpinner();
    });
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");

    for (let i = 0; i < this.loadingTargets.length; i++) {
      this.loadingTargets[i].classList.add("loading");
    }
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");

    for (let i = 0; i < this.loadingTargets.length; i++) {
      this.loadingTargets[i].classList.remove("loading");
    }
  }
}
