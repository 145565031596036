import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['equivalent', 'baseMaterial', 'trimmingMaterial'];

  detectSubmission(event) {
    if (event.key === "Enter") {
      this.render_material(event);      
    }
  }

  get type() {
    return this.data.get('type');
  }

  get rootElement() {
    return document.getElementById('furnitureCustomizer');
  }

  get ujsHelper() {
    return this.application.getControllerForElementAndIdentifier(
      this.rootElement,
      'ujs-helper'
    );
  }

  get url() {
    return this.rootElement.dataset.url;
  }

  get params() {
    return JSON.parse(this.rootElement.dataset.params);
  }

  get materialId() {
    for (let i = 0; i < this.equivalentTargets.length; i++) {
      if (this.equivalentTargets[i].checked) {
        return this.equivalentTargets[i].value;
      }
    }
  }

  render_material(event){
    this.showSpinner();
    let element = event.target;
    let data = {};

    if (element.classList.contains('base-material-search')) {
        data["base_material_search"] = this.baseMaterialTarget.value;
    } else if (element.classList.contains('trimming-material-search')) {
        data["trimming_material_search"] = this.trimmingMaterialTarget.value;
    }

    $.ajax(element.getAttribute('data-url'), {
      method: "GET",
      headers: { 
        "X-CSRF-Token": $("meta[name=csrf-token]").prop("content"),
        contentType: 'text/javascript' 
      },
      dataType: "script",
      data: data != {} ? data : undefined
    })
    .done(() => {
      this.hideSpinner();
    })
    .fail((e) => {
      toastr.error(e.responseText);
    })
  }

  apply() {
    this.showSpinner();

    const reset = {};

    if (this.type === 'base') {
      reset['base_material_orientation'] = '';
    }

    $.ajax({
      url: this.url,
      data: {
        ...this.params,
        [`${this.type}_material_id`]: this.materialId,
        ...reset,
      },
    })
    .done(() => {
      this.hideSpinner();
    });

    this.closeOverlay();
  }

  closeOverlay() {
    this.application
      .getControllerForElementAndIdentifier(
        document.getElementById('overlay'),
        'overlay'
      )
      .close();
  }

  showSpinner() {
    this.ujsHelper.showSpinner();
  }

  hideSpinner() {
    this.ujsHelper.hideSpinner();
  }
}
