import { Controller } from "stimulus";

export default class extends Controller {
  toggle_feature(e) {
    e.preventDefault();
    
    let form = $(e.target).closest('form');

    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(),
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
      },
      dataType: 'script'
    }).error((e) => {
      toastr.error('There was an issue updating that feature.');
    });
  }
}
