import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'alternateContent'];

  get defaultState() {
    if (this.data.has('defaultState')) {
      return this.data.get('defaultState');
    }
    return 'closed';
  }

  connect() {
    if (this.defaultState === 'closed') {
      this.close();
    } else {
      this.open();
    }
  }

  get closed() {
    return this.data.get('state') === 'closed';
  }

  close() {
    this.data.set('state', 'closed');

    this.contentTargets.forEach((el) => {
      el.classList.add('hidden');
    });
    this.alternateContentTargets.forEach((el) => {
      el.classList.remove('hidden');
    });
  }

  open() {
    this.data.set('state', 'open');

    this.contentTargets.forEach((el) => {
      el.classList.remove('hidden');
    });
    this.alternateContentTargets.forEach((el) => {
      el.classList.add('hidden');
    });
  }

  toggle(event) {
    event.preventDefault();

    if (this.closed) {
      this.open();
    } else {
      this.close();
    }
  }
}
