import { Controller } from 'stimulus';
import noUiSlider from 'nouislider';

export default class extends Controller {
  static targets = ['slider', 'form', 'minField', 'maxField'];

  get api() {
    return this.sliderTarget.noUiSlider;
  }

  get range() {
    if (this._range != null) {
      return this._range;
    }
    this._range = {
      min: parseInt(this.data.get('min'), 10),
      max: parseInt(this.data.get('max'), 10),
    };
    return this._range;
  }

  get minValue() {
    if (this.minFieldTarget.value != '') {
      return this.minFieldTarget.value;
    }
    return this.range.min;
  }

  get maxValue() {
    if (this.maxFieldTarget.value != '') {
      return this.maxFieldTarget.value;
    }
    return this.range.max;
  }

  get value() {
    return [this.minValue, this.maxValue];
  }

  connect() {
    noUiSlider.create(this.sliderTarget, {
      start: this.value,
      range: this.range,
      step: 1,
      format: {
        to: (value) => Math.round(value),
        from: (value) => parseInt(value, 10),
      },
      connect: true,
    });

    this.api.on('slide', (values) => {
      this.setValues(values);
    });

    this.api.on('set', (values) => {
      this.setValues(values);
      $(this.formTarget).trigger('submit.rails');
    });
  }

  disconnect() {
    if (this.api != null) {
      this.api.off();
    }
  }

  apply() {
    this.api.set(this.value);
  }

  setValues(values) {
    const { min, max } = this.range;

    if (values[0] === min) {
      this.minFieldTarget.value = '';
    } else {
      this.minFieldTarget.value = values[0];
    }

    if (values[1] === max) {
      this.maxFieldTarget.value = '';
    } else {
      this.maxFieldTarget.value = values[1];
    }
  }
}
