import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'container',
    'primaryDropdown',
    'secondaryDropdown',
    'detailsContainer',
  ]

  get presetQuery() {
    return this.containerTarget.dataset.presetQuery || "";
  }

  select_product_from_pill(e) {
    this.select_product(e.currentTarget.getAttribute('data-id'))
  }

  select_product_from_dropdown(e) {
    this.select_product(e.currentTarget.value)
  }

  trigger_primary_selection_change(e) {
    let current_selection_index = this.containerTarget.querySelector('.js-secondary:not(.hidden)').options.selectedIndex
    this.containerTarget.querySelectorAll('.js-secondary').forEach((item) => { item.classList.add("hidden") })
    
    let parent_variation_id = e.currentTarget.value
    this.containerTarget.querySelector("[data-parent-variation='"+parent_variation_id+"']").options[current_selection_index].selected = true
    this.containerTarget.querySelector("[data-parent-variation='"+parent_variation_id+"']").classList.remove("hidden")
    this.select_product(this.containerTarget.querySelector('.js-secondary:not(.hidden)').value)
  }

  trigger_secondary_selection_change(e) {
    let secondarySelectedValue = e.currentTarget.value;

    document.querySelectorAll('.js-primary option').forEach((primaryOption) => {
        // Check if there are secondary options with the selected value that refer to this primary option
        let correspondingSecondaryOptions = document.querySelectorAll(`.js-secondary option[value='${secondarySelectedValue}'][data-parent-variation='${primaryOption.value}']`);
        
        // If no corresponding secondary options exist, hide the primary option
        if (correspondingSecondaryOptions.length === 0) {
            primaryOption.classList.add('hidden');
        } else {
            primaryOption.classList.remove('hidden');
        }
    });
}

  select_product(product_id) {
    $.ajax({
      type: "GET",
      url: `/furniture/${product_id}/details${this.presetQuery}`,
      data: {uuid: this.containerTarget.getAttribute("id")},
      dataType: "script",
    });
  }
}
